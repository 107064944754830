export default [
  {
    key: "search",
    label: "field.name",
    type: "text",
  },
  {
    key: 'articleCategoryId',
    label: 'field.articleCategory',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'articleCategory',
    selectionKey: 'id',
    selectionLabel: 'nameEn',
    clearable: true,
  },
  {
    hide: true,
    skip: true,
    cols: 12,
    md: 12,
    lg: 12,
  },
  {
    key: "isHomeFeature",
    label: "field.homeFeature",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.homeFeature", value: "1" },
      { text: "field.notHomeFeature", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "isFeature",
    label: "field.feature",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.feature", value: "1" },
      { text: "field.notFeature", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
